<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class="card">
    <form @submit.prevent="submit()" class="p-fluid" style="margin-left: 20%;margin-right: 24%;">
      <div class="p-field p-grid">
        <label for="id" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          <span style="color:red;margin-right: 4px;">*</span>项目编号:
        </label>
        <div class="p-col">
          <InputText id="id" @change="isCodeExist=false" :readonly="$route.query.action=='view'"
                     v-model.trim="v$.form.code.$model" autofocus/>
          <small class="p-error" v-if="v$.form.code.$invalid && form.$submitted">请选择项目编号</small>
          <small class="p-error" v-if="isCodeExist && form.$submitted">项目编号重复</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="name" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          <span style="color:red;margin-right: 4px;">*</span>项目名称:
        </label>
        <div class="p-col">
          <InputText id="name" :readonly="$route.query.action=='view'" v-model.trim="v$.form.name.$model"/>
          <small class="p-error" v-if="v$.form.name.$invalid && form.$submitted">请输入项目名称</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="type" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          <span style="color:red;margin-right: 4px;">*</span>项目类型:
        </label>
        <div class="p-col">
          <Dropdown :disabled="$route.query.action=='view'" :filter="true" id="type" optionLabel="name"
                    optionValue="code" v-model="v$.form.type.$model" :options="typeList" placeholder="请选择项目类型"/>
          <small class="p-error" v-if="v$.form.type.$invalid && form.$submitted">请选择项目类型</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="position" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          <span style="color:red;margin-right: 4px;">*</span>项目坐标:
        </label>
        <div class="p-col">
          <div class="p-inputgroup">
            <InputText :readonly="$route.query.action=='view'" id="position" v-model.trim="v$.form.position.$model"/>
            <Button v-if="$route.query.action!='view'" label="坐标设置" @click="selectPosition()"
                    class="p-button-outlined p-button-secondary" style="color: #12A25D;margin-left: 10px;"/>
          </div>
          <small class="p-error" v-if="v$.form.position.$invalid && form.$submitted">请输入项目坐标</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          <span style="color:red;margin-right: 4px;">*</span>项目地点:
        </label>
        <div class="p-col">
          <div class="p-grid" style="margin:0; height: 36px">
            <InputText class="p-col p-mr-2" placeholder="国家" v-model="form.country"
                       :class="{'p-invalid':(v$.form.country.$invalid && form.$submitted)}"
            ></InputText>
            <InputText class="p-col p-mr-2" placeholder="省/洲/自治区" v-model="form.province"
                       :class="{'p-invalid':(v$.form.province.$invalid && form.$submitted)}"
            ></InputText>
            <InputText class="p-col p-mr-2" placeholder="市" v-model="form.city"
                       :class="{'p-invalid':(v$.form.city.$invalid && form.$submitted)}"
            ></InputText>
            <InputText class="p-col p-mr-2" placeholder="区/县" v-model="form.district"
                       :class="{'p-invalid':(v$.form.district.$invalid && form.$submitted)}"
            ></InputText>
          </div>
        </div>
        <small class="p-error" style="display: block;width: 100%;margin-left:212px"
               v-if="(v$.form.country.required.$invalid||v$.form.province.$invalid||v$.form.city.$invalid||v$.form.district.$invalid) && form.$submitted">请输入项目地点</small>
        <small class="p-error" style="display: block;width:100%;margin-left:212px;color:rgb(0,0,0);opacity: 0.45;">提示：省/自治区、市、区/县的命名应使用简称，如内蒙古自治区简称内蒙古，阿拉善盟简称阿拉善</small>
      </div>
      <div class="p-field p-grid">
        <label for="locationdetail" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          <span style="color:red;margin-right: 4px;">*</span>详细地点:
        </label>
        <div class="p-col">
          <InputText :readonly="$route.query.action=='view'" id="locationdetail"
                     v-model.trim="v$.form.location.$model"/>
          <small class="p-error" v-if="v$.form.location.$invalid && form.$submitted">请输入详细地点</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="locationdetail" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
        </label>
        <div class="p-col" style="position: relative;">
          <div style="width:auto;height: 250px;flex-grow: 1">
            <show-map ref="showMap" :update-but="false"></show-map>
            <div class="select">
              <Button @click="addOrUpdate" style="width: auto">修改</Button>
            </div>
          </div>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="aim" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          <span style="color:red;margin-right: 4px;">*</span>项目目标:
        </label>
        <div class="p-col">
          <Textarea id="aim" :readonly="$route.query.action=='view'" v-model.trim="v$.form.aim.$model"
                    :autoResize="true" rows="5" cols="30"/>
          <small class="p-error" v-if="v$.form.aim.$invalid && form.$submitted">请输入项目目标</small>
        </div>
      </div>
      <div class="p-field p-grid" v-if="form.type && form.type.indexOf('科研')>-1">
        <label for="effect" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          实验效果:
        </label>
        <div class="p-col">
          <Textarea id="effect" :readonly="$route.query.action=='view'" v-model.trim="v$.form.effect.$model"
                    :autoResize="true" rows="5" cols="30"/>

        </div>
      </div>
      <div class="p-field p-grid">
        <label for="yield" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          <span style="color:red;margin-right: 4px;">*</span>亩产:
        </label>
        <div class="p-col">
          <div style="display:flex;align-items: center">
            <InputText id="yield" :readonly="$route.query.action=='view'"
                       v-model.trim="form.yield"
                       :class="{'p-invalid':(v$.form.yield.$invalid && form.$submitted)}"/>
            <span style="display: inline-block;width:120px;padding-left: 20px;">吨/亩</span>
          </div>
          <small class="p-error" v-if="v$.form.yield.$invalid && form.$submitted">请输入亩产</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="area" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          <span style="color:red;margin-right: 4px;">*</span>种植面积:
        </label>
        <div class="p-col">
          <div class="p-d-flex">
            <InputText id="area" class="p-mr-2" :readonly="$route.query.action=='view'"
                       :class="{'p-invalid':(v$.form.area.$invalid && form.$submitted)}"
                       v-model.trim="v$.form.area.$model"/>
            <Dropdown style="width: 110px;" v-model="form.areaUnit" :options="cities" optionLabel="name"
                      :class="{'p-invalid':(v$.form.areaUnit.$invalid && form.$submitted)}"
                      option-value="name"/>
          </div>
          <small class="p-error" v-if="v$.form.area.$invalid && form.$submitted">请输入种植面积</small>
        </div>
      </div>

      <!--新加-->

      <div class="p-field p-grid" style="margin-bottom: 0;height: 22px;">
        <label class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;"><span
            style="color:red;margin-right: 4px;">*</span>整地条件:</label>
        <div class="p-col p-d-flex" style="height: 21px;">
          <div v-for="(item,index) in allList" :key="index" :style="{marginLeft:index!==0?'10px':'0'}">
            <RadioButton :id="'m'+index" name="menu" :value="item.name" v-model="form.cond"
                         :class="{'p-invalid':(v$.form.cond.$invalid && form.$submitted)}"/>
            <label :for="'m'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </div>
        </div>
      </div>
      <span v-if="v$.form.cond.required.$invalid && form.$submitted"
            style="font-size: 12px;color: red;padding-left: 210px;width: 100%">请输入整地条件！</span>
      <div class="p-field p-grid" style="margin-bottom: 0;margin-top: 26px;">
        <label class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end;"><span
            style="color:red;margin-right: 4px;">*</span>灌溉条件:</label>
        <div class="p-col p-d-flex" style="height: 21px;">
          <div v-for="(item,index) in allLists" :key="index" :style="{marginLeft:index!==0?'10px':'0'}">
            <RadioButton :id="'a'+index" name="menu" :value="item.name" v-model="form.irrigation"
                         :class="{'p-invalid':(v$.form.irrigation.$invalid && form.$submitted)}"/>
            <label :for="'a'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </div>
        </div>
        <span v-if="v$.form.irrigation.required.$invalid && form.$submitted"
              style="font-size: 12px;color: red;display: block;padding-left: 210px;width: 100%">请输入灌溉条件！</span>
      </div>
      <div class="p-field p-grid" style="margin-top: 6px;">
        <label class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end;"></label>
        <div class="p-col" style="display:flex;justify-content: space-between">
          <InputNumber style="width: 50%" placeholder="请输入金额" v-model="form.irrigationPrice"
                       :class="{'p-invalid':(v$.form.irrigationPrice.$invalid && form.$submitted)}"></InputNumber>
          <div style="width: calc(50% - 10px);display: inline-block">
            <Dropdown v-model="form.irrigationPriceUnit" :options="citie"
                      :class="{'p-invalid':(v$.form.irrigationPriceUnit.$invalid && form.$submitted)}"
                      optionLabel="name" option-value="name"/>
          </div>
        </div>
        <span
            v-if="(v$.form.irrigationPrice.required.$invalid || v$.form.irrigationPrice.figure.$invalid || v$.form.irrigationPriceUnit.required.$invalid) && $submitted"
            style="font-size: 12px;color: red;padding-left: 210px;width: 100%;margin-top: 4px;">请输入正确或完整的金额！</span>
      </div>
      <div class="p-field p-grid" style="margin-bottom: 22px">
        <label class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end;"><span
            style="color:red;margin-right: 4px;">*</span>土壤属性:</label>
        <div class="p-col  p-d-flex">
          <span v-for="(item,index) in allLi" :key="index" @click="faklsd(item.id)"
             :style="{marginLeft:index!==0?'10px':'0'}">
            <RadioButton :id="'n'+index" name="menu" :value="item.name" v-model="form.soil"
                         :class="{'p-invalid':(v$.form.soil.$invalid && form.$submitted)}"/>
            <label :for="'n'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </span>
        </div>
        <span v-if="v$.form.soil.required.$invalid && form.$submitted"
              style="font-size: 12px;color: red;padding-left: 210px;width: 100%">请输入土壤属性！</span>
      </div>
      <div class="p-field p-grid" v-if="askey">
        <label class="p-col-fixed" style="width:210px;padding:0 4px;justify-content: flex-end;"></label>
        <div class="p-col">
          <div style="border: 1px solid #cccccc;margin-top: 6px;padding-bottom: 8px;">
            <div class="p-field p-grid" style="margin-bottom: 0">
              <label class="p-col-fixed"
                     style="width:100px;justify-content: flex-end;margin-top: 8px;">土壤属性</label>
              <div class="p-col p-d-flex">
                <Slider v-model="form.soilPh" :min="0" :max="20" style="width: 80px;margin-top: 23px"/>
                <label class="p-col-fixed" style="margin: 13px 5px 0 5px;">pH值</label>
                <InputNumber v-model="form.soilPh" style="width: 100px;margin-top: 8px;"
                ></InputNumber>
              </div>
            </div>

            <div class="p-field p-grid" style="margin-top: 26px;">
              <label class="p-col-fixed" style="width:100px;justify-content: flex-end">含盐量:</label>
              <div class="p-col">
                <Dropdown style="width: 110px;" v-model="form.soilSalt" :options="yan" optionLabel="name"
                          option-value="name"/>
              </div>
            </div>
            <div class="p-field p-grid" style="margin-bottom: 0;">
              <label class="p-col-fixed" style="width:100px;justify-content: flex-end">电导率(EC)</label>
              <div class="p-col p-d-flex">
                <InputNumber style="width: 110px;margin-right: 18px;margin-bottom: 4px;"
                             v-model="form.soilConductivity"
                ></InputNumber>
                <Dropdown style="width: 110px;margin-bottom: 4px;" v-model="form.soilConductivityUnit"
                          :options="dao"
                          option-value="name"
                          optionLabel="name"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:210px;justify-content: flex-end">用于生态治理:</label>
        <div class="p-col p-d-flex">
          <div v-for="(item,index) in yes" :key="index" @click="stzimbss(item)"
               :style="{marginLeft:index!==0?'10px':'0'}">
            <RadioButton :id="'b'+index" name="menu" :value="item.id" v-model="form.isEcoGovernance" @click="checkbox=[]"/>
            <label :for="'b'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </div>
        </div>
      </div>
      <div class="p-field p-grid" v-if="stzimb">
        <label class="p-col-fixed" style="width:210px;justify-content: flex-end">生态治理目标:</label>
        <div class="p-col">
          <p v-for="(item,index) in process" :key="index">
            <Checkbox :id="'c'+index" name="menu" :value="item.name" v-model="checkbox"/>
            <label :for="'c'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </p>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:210px;justify-content: flex-end">菌草成熟后再利用:</label>
        <div class="p-col p-d-flex">
          <div v-for="(item,index) in yes" :key="index" :style="{marginLeft:index!==0?'10px':'0'}">
            <RadioButton :id="'d'+index" name="menu" :value="item.id" v-model="form.isReuse" @click="checkboxs=[]"/>
            <label :for="'d'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </div>
        </div>
      </div>
      <div class="p-field p-grid" v-if="form.isReuse===0">
        <label class="p-col-fixed" style="width:210px;justify-content: flex-end">菌草成熟收获后的加工目标:</label>
        <div class="p-col">
          <p v-for="(item,index) in target" :key="index">
            <Checkbox :id="'e'+index" name="menu" :value="item.name" v-model="checkboxs"/>
            <label :for="'e'+index" style="margin-left: 4px;">{{ item.name }}</label>
          </p>
        </div>
      </div>


      <div class="p-field p-grid">
        <label for="endTime" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          &nbsp;&nbsp;&nbsp;&nbsp;项目周期:
        </label>
        <div class="p-col">
          <Calendar :selectionMode="'range'" :showButtonBar="true" id="endTime" :manualInput="false"
                    v-model="v$.form.endDate.$model" dateFormat="yy/mm/dd" :disabled="$route.query.action=='view'"
                    :yearNavigator="true" :monthNavigator="true" yearRange="2000:2699"/>
          <!--          <Calendar id="endTime" :yearNavigator="true" :monthNavigator="true"  :disabled="$route.query.action=='view'" :manualInput="false" dateFormat="yy/mm/dd"  v-model="v$.form.endDate.$model" />-->
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="comments" class="p-col-fixed" style="width:210px;padding:0 4px;;justify-content: flex-end;">
          &nbsp;&nbsp;&nbsp;&nbsp;备注:
        </label>
        <div class="p-col">
          <Textarea id="comments" :readonly="$route.query.action=='view'" v-model.trim="v$.form.comments.$model"
                    :autoResize="true" rows="5" cols="30"/>
          <small class="p-error" v-if="v$.form.comments.$invalid && form.$submitted">请输入备注</small>
        </div>
      </div>
      <Toolbar style="background: none; border: none;margin-left: 210px;padding-left: 0;">
        <template #left>
          <Button style="background: #12A25D;border-color:#12A25D" v-if="$route.query.action != 'view'"
                  :label="$route.query.action == 'edit' ? '保存' : '创建'" type="submit" class="p-mr-2"/>
          <Button style="border-color:#12A25D;color: #12A25D" label="返回" @click="back()"
                  class="p-mr-2 p-button-outlined"/>
        </template>
      </Toolbar>
    </form>
    <enclosure ref="addOrUpdateRole" v-if="addOrUpdateDisplay"
               @mapInfo="getMapInfo"
               v-on:close="closeDialog"></enclosure>
  </div>

  <Dialog id="mapSelect00" v-model:visible="dialog.show"
          :style="{width: '800px',height:'568px','padding':'0px','overflow':'hidden',}" :header="getTitle()"
          :modal="true" class="p-fluid"
          @hide="hideDialog">
    <div class="p-inputgroup" style="width: 240px;position: fixed;left: 50px;top:100px;">
      <InputText @keypress="$event.keyCode==13?(mapKeywords?searchMap():clearSearch()):{}" v-model="mapKeywords"
                 placeholder="请输入"/>
      <Button style="background: #12A25D;border-color: #12A25D" icon="pi pi-search" @click="searchMap()"/>
      <Button style="background: #12A25D;border-color: #12A25D" v-if="mapKeywords" icon="pi pi-times"
              @click="clearSearch()" class="p-button-secondary"/>
    </div>
    <iframe style="width:100%;height:420px;border: none;" src="/amap.html"></iframe>
    <Toolbar style="background: none; border: none;">
      <template #left>
        <Button style="border-color: #12A25D;color: #12A25D" label="清除坐标" @click="clear()"
                class="p-mr-2 p-button-outlined"/>
      </template>
      <template #right>
        <Button style="border-color: #12A25D;color: #12A25D" label="取消" @click="hideDialog()"
                class="p-mr-2 p-button-outlined"/>
        <Button style="background: #12A25D;border-color: #12A25D" label="确定" @click="selected()" class="p-mr-2"/>
      </template>
    </Toolbar>
  </Dialog>

  <Toast/>

</template>

<script>
import {required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import ShowMap from '@/views/modules/plan/showMap';
import enclosure from '@/views/modules/plan/enclosure';

export default {
  setup: () => ({v$: useVuelidate()}),
  components: {ShowMap, enclosure},
  data() {
    return {
      form: {
        code: null,
        name: null,
        type: null,
        position: null, // 仅供显示，数据库不保存此字段
        positionLon: null,
        positionLat: null,
        province: null,
        city: null,
        district: null,
        location: null,
        aim: null,
        effect: null,
        yield: null,
        area: null,
        startDate: null,
        endDate: null,
        comments: null,
        $submitted: false,
        id: null,
        country: null,
        areaUnit: null,
        cond: null,
        areaMap: null,
        irrigation: null,
        irrigationPrice: null,
        irrigationPriceUnit: null,
        soil: null,
        soilPh: null,
        soilSalt: null,
        soilConductivity: null,
        soilConductivityUnit: null,
        isEcoGovernance: null,
        ecoGovernanceAim: null,
        isReuse: null,
        procAim: null,
      },
      selectedPosition: null,
      typeList: [
        {name: '科研/防风固沙', code: '科研/防风固沙'},
        {name: '科研/根系越冬', code: '科研/根系越冬'},
        {name: '科研/环境监测', code: '科研/环境监测'},
        {name: '生产/育种', code: '生产/育种'},
        {name: '生产/生产田间管理', code: '生产/生产田间管理'},
        {name: '生产/猪饲料-发酵床', code: '生产/猪饲料-发酵床'},
        {name: '生产/种菇', code: '生产/种菇'},
      ],
      breadcrumb: {
        home: {label: '种植项目', to: '#'},
        items: [
          {label: '项目管理', to: '#'},
          {label: '进行中的项目', to: '/project/processing'},
          {label: '编辑项目', to: '#'},
        ],
      },
      dialog: {
        show: false,
      },
      mapKeywords: null,
      isCodeExist: false,

      askey: true,
      checkbox: [],
      checkboxs: [],
      stzimb: false,
      process: [
        {id: 0, name: '水土流失'},
        {id: 1, name: '荒漠化'},
        {id: 2, name: '防风固沙'},
        {id: 3, name: '石漠化'},
        {id: 4, name: '盐碱地'},
        {id: 5, name: '矿山修复'},
        {id: 6, name: '土壤修复'},
        {id: 7, name: '护坡固堤'},
      ],
      target: [
        {id: 0, name: '菌料'},
        {id: 1, name: '饲料'},
        {id: 2, name: '生物燃料'},
        {id: 3, name: '新型板材'},
        {id: 4, name: '纤维材料'},
      ],
      items: [
        {label: '规划地块信息'},
        {label: '种植菌草用途'},
        {label: '其他信息'},

      ],
      yes: [
        {id: 0, name: '是'},
        {id: 1, name: '否'},
      ],

      bools: false,
      bool: true,
      selectedCity: {name: '亩'},
      selec: {name: '元/亩'},
      daindao: {name: 'ms/cm'},
      hanyan: {name: '<0.3%'},
      cities: [
        {name: '亩'},
      ],
      citie: [
        {name: '元/亩'},
      ],
      dao: [
        {name: 'ms/cm'},
        {name: 'μs/cm'},
      ],
      yan: [
        {name: '<0.3%'},
        {name: '0.3~0.6%'},
        {name: '>0.6%'},
      ],
      allList: [
        {id: 0, name: '平地'},
        {id: 1, name: '小于25度坡地'},
        {id: 2, name: '梯田'},
      ],
      allLists: [
        {id: 0, name: '喷灌'},
        {id: 1, name: '滴灌'},
        {id: 2, name: '浸灌'},
        {id: 3, name: '自然降雨'},
      ],
      allLi: [
        {id: 0, name: '红壤'},
        {id: 1, name: '沙壤地'},
        {id: 2, name: '湿地(草甸/沼泽地)'},
        {id: 3, name: '黑土'},
        {id: 4, name: '盐碱地'},
      ],
      addOrUpdateDisplay: false,
    };
  },
  validations() {
    return {
      form: {
        code: {
          required,
        },
        name: {
          required,
        },
        type: {
          required,
        },
        position: {
          required,
        },
        province: {required},
        city: {required},
        district: {required},
        location: {
          required,
        },
        aim: {
          required,
        },
        effect: {},
        yield: {
          required,
        },
        area: {
          required,
        },
        endDate: {},
        comments: {},

        id: {},
        country: {required},
        areaUnit: {required},
        cond: {required},
        irrigation: {required},
        irrigationPrice: {required, figure: this.$fromCheck.figure},
        irrigationPriceUnit: {required},
        soil: {required},
        isEcoGovernance: {},
        ecoGovernanceAim: {},
        isReuse: {},
        procAim: {},
      },
    };
  },
  mounted() {
    let me = this;
    top['setSelectedPosition'] = function(pos) {
      me.selectedPosition = pos;
      me.form.position = pos.lon + ', ' + pos.lat;
      me.form.province = pos.province;
      me.form.city = pos.city;
      me.form.district = pos.district;
      me.form.location = pos.address;
    };

    let query = this.$route.query;
    if (query.action == 'view' || query.action == 'edit') {
      me.$http('/project/get', 'get', {id: query.id}, function(response) {
        me.form = response.data;
        let info = {
          area: response.data.area,
          path: JSON.parse(response.data.areaMap),
          center: [response.data.positionLon, response.data.positionLat],
        };
        me.$nextTick(() => {
          me.$refs.showMap.update(info);
        });
        if (me.form.isEcoGovernance === 0) {
          me.stzimb = true;
        } else {
          me.stzimb = false;
        }
        if (me.form.ecoGovernanceAim) {
          if (me.form.ecoGovernanceAim.indexOf(',') != -1) {
            me.checkbox = me.form.ecoGovernanceAim.split(',');
          } else {
            me.checkbox.push(me.form.ecoGovernanceAim);
          }
        }
        if (me.form.procAim) {
          if (me.form.procAim.indexOf(',') != -1) {
            me.checkboxs = me.form.procAim.split(',');
          } else {
            me.checkboxs.push(me.form.procAim);
          }
        }
        me.form.position = me.form.positionLon + ',' + me.form.positionLat;
        me.selectedPosition = {
          lon: me.form.positionLon,
          lat: me.form.positionLat,
        };

        // 时间非必填
        if (me.form.startDate) {

          // 页面中使用endDate来表示范围
          me.form.endDate = [new Date(me.form.startDate), me.form.endDate ? new Date(me.form.endDate) : null];
        } else {
          me.form.endDate = null;
        }
        // console.log(response.data)
        // console.log('时间区间：'+me.form.endDate)
        if (me.form.soil == '盐碱地') {
          me.askey = true;
        } else {
          me.askey = false;
        }

      });
    }
    if (query.action == 'view') {
      me.breadcrumb.items[2].label = '查看项目';
    }

  },
  methods: {
    closeDialog() {
      this.addOrUpdateDisplay = false;
    },
    getMapInfo(e) {

      if (!this.planId) {
        if (!e) {
          e = this.mapInfo;
        } else {
          this.mapInfo = e;
        }
        if (e) {
          e = JSON.parse(JSON.stringify(e));
          this.form.positionLat = e.center.Q;
          this.form.positionLon = e.center.R;
          if (e.area && e.path) {
            this.form.area = e.area;
            this.form.areaUnit = '亩';
            this.form.areaMap = e.path;
          }
          this.form.city = e.city.city;
          this.form.province = e.city.province;
          this.form.district = e.city.district;

          this.$nextTick(() => {
            this.$refs.showMap.update(e);
          });
        }
      }
    },
    addOrUpdate(item, info) {
      this.addOrUpdateDisplay = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdateRole.init(item, info);
      });
    },
    faklsd(id) {
      if (id == 4) {
        this.askey = true;
      } else {
        this.askey = false;
      }
    },
    stzimbss(item) {
      if (item.id == 0) {
        this.stzimb = true;
      } else {
        this.stzimb = false;
      }
    },
    searchMap() {
      top['searchMap'](this.mapKeywords);
    },
    clearSearch() {
      this.mapKeywords = null;
      top['clearSearch']();
    },
    clear() {
      this.selectedPosition = null;
      this.mapKeywords = null;
      this.$toast.add({severity: 'success', summary: '消息', detail: '已清空所选位置！', life: 3000});
    },
    selected() {
      if (!this.selectedPosition) {
        this.$toast.add({severity: 'warn', summary: '错误', detail: '请选择一个经纬度！', life: 3000});
        return;
      }
      this.dialog.show = false;
    },
    getTitle() {
      if (this.selectedPosition) {
        return '坐标位置 - ' + this.selectedPosition.province + this.selectedPosition.city +
            this.selectedPosition.district + (this.selectedPosition.address ? this.selectedPosition.address : '') +
            '（' + this.selectedPosition.lon + ',' + this.selectedPosition.lat + '）';
      } else {
        return '坐标位置';
      }
    },
    back() {
      this.$router.push('/project/processing');
    },
    selectPosition() {
      this.selectedPosition = null;
      this.mapKeywords = null;
      this.dialog.show = true;
    },
    hideDialog() {
      this.dialog.show = false;
    },
    submit() {
      let me = this;
      this.form.$submitted = true;
      if (this.form.soil !== '盐碱地') {
        this.form.soilPh = 'q'; //土壤ph值
        this.form.soilSalt = 'q';//含盐量
        this.form.soilConductivity = 'q'; //电导率
        this.form.soilConductivityUnit = 'q'; //电导率单位
      }
      this.checkboxs = this.checkboxs.filter(item => {
        return item.length > 1;
      });
      this.checkbox = this.checkbox.filter(item => {
        return item.length > 1;
      });
      this.form.ecoGovernanceAim = this.checkbox.toString();
      this.form.procAim = this.checkboxs.toString();

      if (this.v$.$invalid) {
        return;
      }
      if (this.form.soil != '盐碱地') {
        this.form.soilPh = null; //土壤ph值
        this.form.soilSalt = null;//含盐量
        this.form.soilConductivity = null; //电导率
        this.form.soilConductivityUnit = null; //电导率单位
      }

      // 处理字段内容
      this.form.positionLon = this.selectedPosition.lon;
      this.form.positionLat = this.selectedPosition.lat;

      // 结束时间非必填
      if (this.form.endDate) {
        this.form.startDate = this.form.endDate[0];
        this.form.endDate = this.form.endDate[1];
      } else {
        this.form.startDate = null;
        this.form.endDate = null;
      }

      this.$http('/project/saveOrUpdate', 'post', this.form, (res) => {
        me.$toast.add({severity: 'success', summary: '成功', detail: '项目添加成功！', life: 3000});
        me.back();
      }, (err) => {
        if (err.code == '875698') {
          this.isCodeExist = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep(.p-dropdown .p-dropdown-trigger) {
  color: #12A25D;
}

.p-col {
  padding: 0 4px;
}

::v-deep(.p-calendar-w-btn .p-button) {
  border-color: #12A25D;
  background: #12A25D;
}

.select {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
<style>
#mapSelect00 .p-dialog-content::-webkit-scrollbar {
  display: none;
}
</style>
